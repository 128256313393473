<template>
  <div class="time-line" :style="'height:' + height + 'px;'">
    <div class="time-line-content">
      <div
        class="time-line-content-left"
        v-if="$slots.left"
        :style="'left: -' + offsetLeft + 'px;'"
      >
        <slot name="left"></slot>
      </div>
      <div class="time-line-content-right" v-if="$slots.right">
        <slot name="right"></slot>
      </div>
      <div
        class="time-line-content-icon"
        :style="iconcolor == '' ? '' : 'background-color:' + iconcolor"
      >
        <i :class="icon" v-if="icon != ''"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "time-line",
  components: {},
  data() {
    return {
      offsetLeft: 40,
      height: 60,
    };
  },
  computed: {},
  props: {
    icon: {
      //从父组件传入的数据
      type: String,
      default: function() {
        return "";
      },
    },
    iconcolor: {
      //从父组件传入的数据
      type: String,
      default: function() {
        return "";
      },
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.text();
    });
  },
  methods: {
    text() {
      if (this.$slots.left) {
        this.offsetLeft += this.$slots.left[0].elm.clientWidth;

        if (this.$slots.left[0].elm.clientHeight > this.height) {
          this.height = this.$slots.left[0].elm.clientHeight + 30;
        }
      }
      if (
        this.$slots.right &&
        this.$slots.right[0].elm.clientHeight > this.height
      ) {
        this.height = this.$slots.right[0].elm.clientHeight + 30;
      }
    },
  },
  watch: {},
};
</script>
<style lang="less">
.time-line {
  width: 100%;
  position: relative;

  &-content {
    &-left {
      position: absolute;
      left: -300px;
    }
    &-right {
      position: absolute;
      left: 40px;
    }
    &-icon {
      position: absolute;
      top: -2px;
      left: -24px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      z-index: 100;
      background-color: rgb(95, 216, 224);
      text-align: center;
      i {
        line-height: 50px;
        font-size: 34px;
        color: #fff;
      }
    }
    z-index: 99;
    position: relative;
    border: 2px solid #eeeded;
    height: 100%;
    width: 2px;
    background-color: #eeeded;
    left: calc(~"50% - 3px");
  }
}
</style>
