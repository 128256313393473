<template>
  <div class="apiface-home">
    <div
      class="header-wrap"
      :class="{
        'float-header': toTopShow,
      }"
    >
      <div class="header-container container">
        <a :href="model.href">{{ model.title }}</a>
        <span class="fr" v-for="(item, index) in model.links" :key="index">
          <a :href="item.href">{{ item.title }}</a></span
        >
      </div>
    </div>
    <div class="header-images">
      <div
        v-show="activeIndex == index"
        class="header-images-item"
        v-for="(item, index) in model.images"
        :key="index"
        :style="'background-image: url(' + item.url + ');'"
      >
        <div class="start-btn" :class="'start-btn-' + (index % 5)">
          <h1 class="start-btn-title">{{ item.title }}</h1>
          <br />
          <h2 class="start-btn-title">{{ item.subtitle }}</h2>
          <br />
          <a href="#home">{{ model.startBtn }}</a>
        </div>
      </div>
    </div>
    <div class="content-wrapper content-gax container">
      <h3 class="widget-title">
        {{ model.feature }}
      </h3>
      <div
        class="content-wrapper-item"
        v-for="(item, index) in model.features"
        :key="index"
      >
        <div class="content-wrapper-item-icon-box">
          <div class="content-wrapper-item-icon-box-icon">
            <i :class="item.icon"></i>
          </div>
          <div class="content-wrapper-item-content">
            <h3>
              {{ item.title }}
            </h3>
            <p>
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>

    <div id="home">
      <div class="container content-gax">
        <h3 class="widget-title widget-title-while">
          {{ model.step }}
        </h3>
        <timeline
          :icon="item.icon"
          :iconcolor="item.color"
          v-for="(item, index) in model.steps"
          :key="index"
        >
          <div
            class="step-content"
            :slot="key"
            v-for="(val, key, count) in item.data"
            :key="count"
          >
            <h3>{{ val.title }}</h3>
            <span class="timeline-date">
              <a :href="val.href" target="_blank">{{ val.tip }}</a>
            </span>
            <div>
              <pre>{{ val.code }}</pre>
            </div>
          </div>
        </timeline>
      </div>
    </div>

    <div class="content-teach content-gax container">
      <h3 class="widget-title">
        {{ model.teach }}
      </h3>
      <div
        class="content-teach-item"
        v-for="(item, index) in model.teachs"
        :key="index"
      >
        <div class="content-teach-item-content">
          <h4 class="entry-title">
            <a :href="item.href"> {{ item.title }}</a>
          </h4>
          <div>
            <p>
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>

    <!-- 版权信息 -->
    <div class="tip-foot">
      <a href="http://self.daysh.top" target="_blank">{{ model.copyright }}</a>
    </div>
  </div>
</template>
<script>
import Timeline from "components/timeline";
export default {
  name: "songlist",
  components: { Timeline },
  data() {
    return {
      activeIndex: 0,
      flag: false,
      scrollTop: 0,
      toTopShow: false,
      model: {
        title: "",
        href: "",
        startBtn: "",
        images: [],
        links: [],
        feature: "",
        features: [],
        step: "",
        steps: [],
        copyright: "",
      },
      moveImage: null,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll, true); // 取消事件冒泡，防止绑定失败
      this.loadConfig();
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true); // 取消事件冒泡
  },
  methods: {
    loadConfig() {
      this.$getch(
        "home.json",
        "get",
        {},
        { Pragma: "no-cache", "Cache-Control": "no-cache" }
      )
        .then((res) => {
          this.model = res;
          if (res && res.images && res.images.length > 0) {
            this.move();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    move() {
      this.moveImage = setInterval(() => {
        this.activeIndex = ++this.activeIndex % this.model.images.length;
      }, 6 * 1000);
    },
    handleScroll() {
      let dom = document.getElementsByClassName("apiface-home")[0];
      this.scrollTop = dom.scrollTop;
      if (this.scrollTop > 30) {
        this.toTopShow = true;
      } else {
        this.toTopShow = false;
      }
    },
  },
  watch: {},
};
</script>
<style lang="less">
.apiface-home {
  height: 100%;
  overflow-y: auto;
  .container {
    min-width: 970px;
    width: 1000px;
    margin-right: auto;
    margin-left: auto;
    .widget-title-while {
      color: #fff !important;
      &::after {
        background-color: #fff !important;
      }
    }
    .widget-title {
      font-size: 32px;
      text-align: center;
      position: relative;
      margin-bottom: 50px;
      font-weight: 600;
      line-height: normal;
      text-transform: lowercase;
      padding-bottom: 10px;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 50px;
        height: 1px;
        margin-left: -25px;
        background-color: #d65050;
      }
    }
  }
  #home {
    background-color: #d65050;
    // .time-line-content-left {
    //   .step-content {
    //     animation: 1.2s bounceInRight;
    //   }
    // }
    // .time-line-content-right {
    //   .step-content {
    //     animation: 1.2s bounceInRight;
    //   }
    // }
    .step-content {
      width: 480px;
      border-radius: 15px;
      border: 2px solid #ebebeb;
      padding: 15px;
      background-color: #fff;
      h3 {
        font-size: 20px;
        margin: 0 0 15px;
        color: rgb(68, 63, 63);
      }
      .timeline-date {
        display: block;
        margin-bottom: 10px;
        color: #bebebe;
        a:hover {
          text-decoration: underline;
        }
      }
      pre {
        display: block;
        padding: 9.5px;
        margin: 0px;
        font-size: 13px;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  }
  .content-gax {
    padding: 50px 0px;
  }
  .content-teach {
    color: #877f7f;
    &-item {
      width: 33.33%;
      float: left;
      margin-bottom: 20px;
      padding-right: 35px;
      padding-left: 35px;

      &-content {
        h4 {
          margin: 15px 0;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          text-align: center;
          a {
            color: #d65050;
          }
        }
        p {
          margin: 0 0 10px;
        }
      }
    }
  }
  .content-wrapper {
    color: #877f7f;
    &-item {
      width: 33.33%;
      float: left;
      margin-bottom: 20px;
      padding-right: 35px;
      padding-left: 35px;
      &-content {
        h3 {
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          margin: 7px 0 10px;
        }
        p {
          line-height: 20px;
        }
      }
      &-icon-box {
        text-align: center;

        &-icon {
          text-align: center;
          width: 70px;
          height: 70px;
          line-height: 75px;
          margin: 0 auto 20px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          color: #d65050;
          border: 1px solid #d65050;
          i {
            font-size: 26px;
          }
        }
      }
    }
  }
  .header-images {
    overflow: hidden;
    width: 100%;
    height: 100%;

    &-item {
      position: relative;
      height: 100%;
      .start-btn {
        position: absolute;
        top: 46%;
        text-align: center;
        width: 100%;
        .start-btn-title {
          color: #fff;
        }
        &.start-btn-0 {
          h1 {
            animation: 1.2s bounceInLeft;
          }
          h2 {
            animation: 1.2s bounceInRight;
          }
        }
        &.start-btn-1 {
          h1 {
            animation: 1.2s zoomInLeft;
          }
          h2 {
            animation: 1.2s zoomInRight;
          }
        }
        &.start-btn-2 {
          h1 {
            animation: 1.2s flipInX;
          }
          h2 {
            animation: 1.2s flipInY;
          }
        }
        &.start-btn-3 {
          h1 {
            animation: 1.2s bounceInDown;
          }
          h2 {
            animation: 1.2s bounceInUp;
          }
        }
        &.start-btn-4 {
          h1 {
            animation: 1.2s fadeInLeft;
          }
          h2 {
            animation: 1.2s fadeInRight;
          }
        }
        h1 {
          font-size: 48px;
        }
        h2 {
          font-size: 28px;
          font-weight: 500;
          padding: 0px 0px 28px 0px;
        }
        a {
          padding: 10px 20px;
          color: #fff;
          font-size: 14px;
          border-radius: 3px;
          cursor: pointer;
          background-color: #d65050;
          border: 1px solid #d65050;
        }
      }
    }
  }
  .header-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: transparent;
    padding: 20px 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    &.float-header {
      position: fixed !important;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 5px;
    }
    .header-container {
      line-height: 40px;
      span {
        color: #fff;
        padding-left: 30px;
        font-size: 14px;
        font-weight: 600;
        &:last-child {
          padding-left: 0px !important;
        }
      }
      a {
        color: #fff;
        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
      & > a {
        font-weight: 700;
        font-size: 28px;
        margin: 0;
      }
    }
  }
  .tip-foot {
    padding-bottom: 20px;
    text-align: center;
    width: 100%;
    padding-top: 20px;
  }
}
</style>
